import React, { useCallback } from 'react'
import { LoadingButton } from '@mui/lab'
import { Alert, Link, Stack, Typography } from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import FormTextInput from 'src/components/atoms/FormTextInput'
import { PasswordInput } from 'src/components/atoms/PasswordInput/PasswordInput'
import LandingScreenContainer from 'src/components/views/Login/LandingScreenContainer'
import { LoginLanguageToggle } from 'src/components/views/Login/LoginLanguageToggle'
import {
  RESTAURANT_HASH_STORAGE_KEY,
  useSelectedRestaurantHashAtom,
} from 'src/context/state/atoms/selectedRestaurant'
import { useAuthMutation } from 'src/hooks/query/auth'
import { useRestaurantsQueryCallback } from 'src/hooks/query/restaurants'
import { isLoginSuccess } from 'src/service/authApi'
import { type AllRestaurants } from 'src/types/allRestaurants'

export interface LoginScreenProps {
  onLogin: () => void
}

const loginSchema = z.object({
  email: z.string().nonempty(),
  password: z.string().nonempty(),
})

const getValidHashFromStorage = (restaurants: AllRestaurants) => {
  if (import.meta.env.MODE === 'production') return undefined

  const hashFromStorage = localStorage.getItem(RESTAURANT_HASH_STORAGE_KEY)

  if (!hashFromStorage) return undefined

  return restaurants.restaurants.find(r => r.hash === hashFromStorage)?.hash
}

type LoginFormValues = z.infer<typeof loginSchema>

export const LoginScreen = ({ onLogin }: LoginScreenProps) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<LoginFormValues>({
    resolver: zodResolver(loginSchema),
  })

  const { mutateAsync: authenticate } = useAuthMutation()
  const ensureRestaurants = useRestaurantsQueryCallback(true)

  const [, setRestaurantHash] = useSelectedRestaurantHashAtom()

  const login = React.useCallback(async () => {
    const restaurants = await ensureRestaurants()

    setRestaurantHash(
      getValidHashFromStorage(restaurants) ?? restaurants.info.hash,
    )
    onLogin()
  }, [ensureRestaurants, onLogin, setRestaurantHash])

  React.useEffect(() => {
    login().catch(() => {})
  }, [login])

  const submitWithErrorHandling = useCallback(
    async (formData: LoginFormValues) => {
      const result = await authenticate({
        username: formData.email,
        password: formData.password,
      })

      if (!result) return
      if (isLoginSuccess(result)) {
        await login().catch(() => {})
        return
      }

      setError('root', {
        message: t(
          `auth_errors.${result.message}`,
          result.longMessage ?? 'Something went wrong',
        ),
      })
    },
    [authenticate, login, setError, t],
  )

  return (
    <LandingScreenContainer>
      <Typography
        sx={{ textAlign: 'center' }}
        component="h2"
        variant="labelGiant"
        mb={0.25}
      >
        {t('login.title', {
          defaultValue: 'Login to foratable',
          tDescription: 'Login screen title',
        })}
      </Typography>
      <Typography sx={{ textAlign: 'center', mb: 4 }} variant="body2">
        {t('login.subtitle', {
          defaultValue: 'Enter e-mail and password to login',
          tDescription: 'Login screen subtitle',
        })}
      </Typography>
      {errors.root?.message && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errors.root.message}
        </Alert>
      )}

      <Stack
        component="form"
        gap={3}
        mb={2}
        onSubmit={handleSubmit(submitWithErrorHandling)}
      >
        <FormTextInput
          label={t('common.email')}
          type="text"
          autoComplete="email"
          error={!!errors.email?.message}
          {...register('email')}
        />
        <Stack gap={1}>
          <PasswordInput
            label={t('angular.password')}
            error={!!errors.password?.message}
            {...register('password')}
          />
          <Link
            href={`${import.meta.env.PUBLIC_FAT_URL}register/forgotten`}
            target="_blank"
            rel="noopener"
            sx={{
              textDecoration: 'none',
              typography: 'labelSmall',
              textAlign: 'right',
            }}
          >
            {t('common.forgot_password', {
              defaultValue: 'Forgot password?',
              tDescription: 'Forgot password link on login screen',
            })}
          </Link>
        </Stack>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          loading={isSubmitting}
        >
          {t('angular.login')}
        </LoadingButton>
      </Stack>
      <LoginLanguageToggle />
    </LandingScreenContainer>
  )
}
