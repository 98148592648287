import { z } from 'zod'

export enum Colors {
  blue = '#44AAFF',
  pink = '#FF83EC',
  red = '#FF0000',
  yellow = '#FEFF44',
  green = '#57FF5E',
  grey = '#A4A4A4',
  white = '#FFFFFF',
}

export const colorSchema = z
  .enum(['blue', 'pink', 'red', 'yellow', 'green', 'grey', 'white'] as const)
  .transform(d => Colors[d])
  .or(z.string().startsWith('#'))
  .catch('#44AAFF')
