import { forwardRef, useState } from 'react'
import { IconButton, InputAdornment } from '@mui/material'

import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

import FormTextInput, { type FormTextInputProps } from '../FormTextInput'

export const PasswordInput = forwardRef<HTMLDivElement, FormTextInputProps>(
  ({ slotProps, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
      <FormTextInput
        ref={ref}
        autoComplete="password"
        type={showPassword ? 'text' : 'password'}
        slotProps={{
          ...slotProps,
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(show => !show)}
                  onMouseDown={e => e.preventDefault()}
                  sx={{ color: 'grey.800' }}
                >
                  {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </IconButton>
              </InputAdornment>
            ),
            ...slotProps?.input,
          },
        }}
        {...props}
      />
    )
  },
)
